import React from "react"
import { Link } from "gatsby"
import icoAllianz from "../assets/old/img/logos-ase/ico-allianz.png"
import icoExperta from "../assets/old/img/logos-ase/ico-experta.png"
import icoOrbis from "../assets/old/img/logos-ase/ico-orbis.png"
import icoProvincia from "../assets/old/img/logos-ase/ico-provincia.png"
import icoRus from "../assets/old/img/logos-ase/ico-rus.png"
import icoSancor from "../assets/old/img/logos-ase/ico-sancor.png"
import icoCristobal from "../assets/old/img/logos-ase/ico-sancristobal.png"
import icoSura from "../assets/old/img/logos-ase/ico-sura.png"
import icoMercantil from "../assets/old/img/logos-ase/ico-mercantilandina.png"
import icoParana from "../assets/old/img/logos-ase/parana-seguros.png"
import icoGaleno from "../assets/old/img/logos-ase/ico-galeno.png"
import icoBerkley from "../assets/old/img/logos-ase/ico-berkley.png"

export default () => (
  <>
    <section className="container" data-offset-top="110" id="aseguradoras">
      <div className="container pt-1 mt-2 pb-5">
        <h2 className="h3 block-title text-center pt-5 mt-2">Confían en nosotros</h2>
        <div className="row pt-4">
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/allianz">
              <img
                className="d-block mx-auto"
                src={icoAllianz}
                style={{ padding: "30px 0" }}
                alt="Allianz Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/experta">
              <img
                className="d-block mx-auto"
                src={icoExperta}
                style={{ padding: "30px 0" }}
                alt="Experta Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/orbis">
              <img
                className="d-block mx-auto"
                src={icoOrbis}
                style={{ padding: "30px 0" }}
                alt="Orbis Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/provincia">
              <img
                className="d-block mx-auto"
                src={icoProvincia}
                style={{ padding: "30px 0" }}
                alt="Provincia Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/rus">
              <img
                className="d-block mx-auto"
                src={icoRus}
                style={{ padding: "30px 0" }}
                alt="Rio Uruguay Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/sancor">
              <img
                className="d-block mx-auto"
                src={icoSancor}
                style={{ padding: "30px 0" }}
                alt="Sancor Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link
              className="d-block bg-white box-shadow px-2 mx-auto"
              to="/siniestros/san-cristobal"
            >
              <img
                className="d-block mx-auto"
                src={icoCristobal}
                style={{ padding: "30px 0" }}
                alt="San Cristobal Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/sura">
              <img
                className="d-block mx-auto"
                src={icoSura}
                style={{ padding: "30px 0" }}
                alt="Sura Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link
              className="d-block bg-white box-shadow px-2 mx-auto"
              to="/siniestros/mercantil-andina"
            >
              <img
                className="d-block mx-auto"
                src={icoMercantil}
                style={{ padding: "30px 0" }}
                alt="Mercantil Andina"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/parana">
              <img
                className="d-block mx-auto"
                src={icoParana}
                style={{ padding: "30px 0" }}
                alt="Parana Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/galeno">
              <img
                className="d-block mx-auto"
                src={icoGaleno}
                style={{ padding: "30px 0" }}
                alt="Galeno Seguros"
              />
            </Link>
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
            <Link className="d-block bg-white box-shadow px-2 mx-auto" to="/siniestros/berkley">
              <img
                className="d-block mx-auto"
                src={icoBerkley}
                style={{ padding: "30px 0" }}
                alt="Berkley Argentina Seguros"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  </>
)
